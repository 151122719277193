import React from 'react'

function Inicio() {
    

    return(
        <div>
            HOLA COMO ESTAS CLIENTE TIGO
        </div>
    )
}
export default Inicio;